@import "./variables/Colors";
@import "./variables/Directions";

  $margin_value: 0.25;

  @for $i from 1 through 6 {
    .margin-left-#{$i} {
      margin-inline-start: #{$margin_value}rem !important;
    }
    .margin-right-#{$i} {
      margin-inline-end: #{$margin_value}rem !important;
    }
    @if $i == 1 or $i%2==0{
      $margin_value: $margin_value * 2
    }@else {
      $margin_value: $margin_value * 1.5
    }

  }

.full-screen{
  position: absolute !important;
  z-index: 2000 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 100vh !important;
}

.margin-left-auto {
  margin-inline-start: auto !important;
}

.margin-right-auto {
  margin-inline-start: auto !important;
}

  .text-start {
    text-align: start !important;
  }

  .text-end {
    text-align: end !important;
  }

  .float-start{
    float: start;
  }

  .float-end {
    float: end;
  }

.icon-flip{
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.primary-color{
  color: $base-color;
}

.primary-bg{
  background: $base-color !important;
}

.pre-line{
  white-space: pre-line;
}

.border-radius{
  border-radius: 5px !important;
}

.disabled-field {
  background: rgba(0, 0, 0, 0.05);
}

.overflow-x-hidden {
  overflow-x: hidden;
}


.Paper {
  padding: 1rem 2rem 2rem 2rem;
  overflow: hidden;
  text-align: start;
}

.list-box-autocomplete {
  height: auto !important;
  max-height: 50vh !important;
  overflow-y: auto;
  padding: 5px 0 0 0 !important;
}

.list-box-autocomplete::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.list-box-autocomplete::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.list-box-autocomplete::-webkit-scrollbar-thumb {
  background: darkgray;
}

/* Handle on hover */
.list-box-autocomplete::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-modal {
  width: 50% !important;
  margin: 12vh auto 12vh !important;
  background: white;
  border-radius: 10px;
  //box-shadow:0 1rem 3rem rgba(0,0,0,.175)!important
}

.Drag-n-Drop{
  text-align: center;
  border-radius: 5px;
  border: 2px dashed $base-color;
  height: 200px;
  background: rgba(0,0,0,0.03);
  width: 100%;
  color: $base-color;
  padding: 3rem 1rem 1rem 1rem;
}

.error-box{
  box-sizing: content-box;
  border: 2px solid red;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 0.5rem  1rem 0.5rem !important;
  background: rgba(crimson,0.1);
  color: crimson;
}
.success-box{
  box-sizing: content-box;
  border: 2px solid green;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 0.5rem  1rem 0.5rem !important;
  background: rgba(green,0.1);
  color: green;
}

.card-paper-1{
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background: white;
  border-radius: 10px
}

.card-paper-2{
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background: rgba(255,255,255,0.9);
  border-radius: 10px
}

.background-1{
  background-image: url("../images/backround_7.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}
//
//.img-top: {
//'display:'flex',
//'position:'absolute',
//'top':0,
//'left':0,
//'z-index':99,
//
//}
.stepper {
  padding: 1rem !important;
  margin-bottom: 1rem !important;
  background: none !important;
}

.full-screen{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  min-width: 100vw;
  min-height: 100vh;
  height: auto!important;
}
@media (max-width: 695px) {
  .Paper {
    padding: 1rem;
  }

  .stepper {
    display: block !important;
  }

  .step {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .card-paper-1{
    padding: 1rem;
  }

  .form-modal {
    margin-top: 8vh !important;
    width: 90% !important;
  }
}

@media (max-width: 959px) {
  .arrow {
    transform: rotate(90deg);
  }

}

