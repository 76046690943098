@import "variables/Colors";
@import "variables/Directions";

.root{
  height: 100vh !important;
  width: 100% !important;
  overflow-y: hidden;
  overflow-x: hidden;
  border:1px solid;
}

.sub-root{
  min-height: 100vh;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.container-body {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 30px;
}

.SearchField{
  height: 45px;
  background-color: #fff;
  border-color: transparent;
  padding-inline-start: 20px;
  font-weight: 500;
}


.side-menu{
  width:300px !important;
  max-width:300px !important;
  min-width:300px !important;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,1) !important;
  background: $base-color !important;
  color: white !important;
  padding: 0 10px;
  text-decoration: none;
  font-family: "Quicksand",sans-serif !important;
  a:focus, a:hover, button:focus, button:hover  {
    text-decoration: none !important;
    color: white !important;
  }
}

.side-menu-item{
  font-family: "Quicksand",sans-serif !important;
  color: white !important;
  transition: 0.5s all !important;
}

.container-header {
  position: static;
  z-index: -1;
  //background: $base-color;
  width: 100%;
  height: 35px;
}

@media (max-width: 850px) {
  .container-body {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 30px;
  }
}
