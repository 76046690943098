.home-root {
  height: 100vh
}

.image {
  background-image: url("../images/NEON_Logo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.paper {
  margin-top: 4rem !important;
  margin-right: 2rem !important;
  margin-left: 2rem !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.avatar {
  margin: 1rem !important;
  background: black !important;
}

.login-container {
  width: 100% !important;
  margin-top: 1rem !important;
}

.submit {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  background: black !important;
}

